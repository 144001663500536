.radioGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.radio {
  padding: 4px !important;
}

.icon {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  height: 54px;
  justify-content: center;
  width: 54px;
}
