.carousel,
.carousel-slider {
  height: 100%;
}

.carousel .slide {
  background: white;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 0 6%;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 0;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  text-align: center;
  opacity: 1;
  background: #303d4a;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  margin-left: 10px;
}

/* TODO: focus and hover don't work as expected here, neither custom arrows */
.carousel .control-next.control-arrow:focus {
  background: #303d4a;
  opacity: 1;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #5a6776;
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  border-left: 12px solid #fff;
  margin-left: 17%;
}

.carousel .control-prev.control-arrow:before {
  border-right: 12px solid #fff;
  margin-right: 17%;
}

.carousel .control-dots .dot {
  background: #303d4a;
}

.carousel .slide img {
  width: auto;
}

.carousel img {
  width: auto;
}

.carousel-container-override {
  height: 100%;
}

.carousel .slider-wrapper,
.carousel .slider-wrapper.axis-horizontal .slider,
li.slide {
  height: inherit;
}
