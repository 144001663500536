.content {
  height: 100%;
  width: 800px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.header {
  padding: 0 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.body {
  padding: 20px 20px 70px 20px;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  align-content: flex-start;
}

.footer {
  bottom: 0px;
  position: sticky;
  z-index: 999;
  background-color: white;
  border-top: 1px solid #091722;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
}

.title {
  display: flex;
}

@media screen and (max-width: 600px) {
  .content {
    width: 100%;
  }

  .body {
    grid-template-columns: auto;
  }
}
