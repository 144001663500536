* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #f2f2f2 !important;
  color: #303d4a !important;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
}

.mapInfoBubble .H_ib_body .H_ib_content {
  margin: 0;
}

.mapInfoBubble .H_ib_body .H_ib_close {
  display: none;
}

.mapInfoBubble .H_ib_body {
  border-radius: 10px;
}

.mapInfoBubble .imgInfoBubble {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  div.divHeader {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10mm;
  }
}

@media print {
  .printable-page {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    height: 297mm;
    width: 210mm;
    padding: 10mm;
  }
}

@page {
  size: A4 portrait;
  /*
  padding: 10mm;
  */
}

.printable-page:last-child {
  page-break-after: avoid;
}
