.markdown {
  font-size: 14px;
}

.markdown h1 {
  padding-top: 10px;
}

.markdown h2 {
  display: block;
  color: #303d4a;
  background: #f2f2f2;
  padding: 10px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 10px;
}

.markdown h3 {
  border-bottom: 1px solid #02a9b9;
  padding-top: 10px;
  margin-bottom: 10px;
}

.markdown h4 {
  padding-top: 10px;
  color: #5a6776;
  border-bottom: 1px solid #f2f2f2;
}

.markdown ul {
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 20px;
  list-style-type: disc;
}

.markdown ul ul {
  list-style-type: circle;
}

.markdown li {
  font-size: inherit;
  overflow: inherit;
  padding: 0;
  margin-bottom: 0;
}

.markdown img {
  width: 100%;
  height: auto;
}

.markdown hr {
  height: 1px;
  border: 0;
  /*background: #02A9B9;*/
}

.markdown img {
  margin-top: 10px;
  padding-bottom: 10px;
}

.markdown .language-plain {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 10px;
}

.markdown pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.markdown h1,
h3,
h4,
p {
  margin-left: 5px;
  margin-right: 5px;
}

.markdown a {
  color: #02a9b9;
}

.markdown p {
  padding-top: 6px;
  padding-bottom: 6px;
}

.markdown:last-child {
  margin-bottom: 10px;
}
