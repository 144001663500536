.searchbar {
  display: flex;
}

.searchbarItemFull {
  flex: 1;
  padding-right: 1em;
}

.searchbarItemSmall {
  flex: 0;
}
