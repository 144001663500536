.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 24px;
}

.itemA {
  grid-column-start: 1;
}

.itemB {
  grid-column-start: 2;
}

.itemC {
  grid-column-start: 3;
}

.itemD {
  grid-column-start: 3;
}
